import './footer.scss';
import XenithFooter from '../../Assets/Xenith-Footer.svg';
import { motion, useScroll, useTransform} from 'framer-motion';
import { useRef, } from 'react';

const Footer = () => {

 
    const element = useRef(null);

    
    const { scrollYProgress } = useScroll({
        target: element,
        offset: ['start end', 'start 0.2']
    })

        const scale = useTransform(scrollYProgress, [0, 0.9], [0.9, 1]);


    return(
        <motion.div 
        ref={element}
        style={{scale: scale}}
        
        class="footer-container">
            <div
            
            class="footer-start">
                <div>
                    <h4>GET IN TOUCH</h4>
                    <h5>sales@xenithstudio.com</h5>
                </div>
                <div class="scroll-button-top" id="scroll-button-top">
                    <p>BACK TO TOP</p>
                    <div class="arrow-top"></div>
                    
                </div>

            </div>
            <div
            
             class="footer-center">
                <ul class="page-links">
                    <p class="list-title">LINKS</p>
                    <li onClick={() => ScrollToSec('hero')}>Home</li>
                    <li onClick={() => ScrollToSec('services')}>Services</li>
                    <li onClick={() => ScrollToSec('portfolio')}>Portfolio</li>
                    <li onClick={() => ScrollToSec('clients')}>Clients</li>
                    <li onClick={() => ScrollToSec('testimonials')}>Testimonials</li>

                </ul>
                <ul class="social-links">
                    <p class="list-title">SOCIALS</p>
                    <li onClick={() => window.open("https://www.instagram.com/creatorlogs/", '_blank')}>Instagram</li>
                    <li onClick={() => window.open("https://www.linkedin.com/company/xenithstudio/", '_blank')}>LinkedIn</li>
                </ul>

            </div>
            <div
            
             class="footer-end">
                <p>Copyright © Xenith Studio 2024</p>
                <img src={XenithFooter} alt="Xenith Footer" class="xenith-footer"></img>

            </div>
            
        </motion.div>
    )

    function ScrollToSec(type){
        const services = document.querySelector(".services-container");
        const portfolio = document.querySelector(".portfolio-container");
        const clients = document.querySelector(".clients-container");
        const hero = document.querySelector('.nav-container');
        const menu = document.querySelector('.nav-divider-mobile')
        const testimonials = document.querySelector('.testimonials-container')
        

        if(type==="services"){
            services.scrollIntoView({behavior: 'smooth', block: "start"})
            menu.style.display = 'none';

        }
        else if(type==="portfolio"){
            portfolio.scrollIntoView({behavior: 'smooth', block: "center"})
            menu.style.display = 'none';
            
        }
        else if(type==="clients"){
            clients.scrollIntoView({behavior: 'smooth', block: "center"})
            menu.style.display = 'none';

        }
        else if(type==="testimonials"){
            testimonials.scrollIntoView({behavior: 'smooth', block: "start"})
            menu.style.display = 'none';

        }
        else if(type==='hero'){
            hero.scrollIntoView({behavior: 'smooth', block: "start"})
            menu.style.display = 'none';
        }
    }
}

export default Footer