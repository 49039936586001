
import './App.css';
import Navbar from './Sections/Navbar/navbar';
import Hero from './Sections/Hero/hero';
import Slogan from './Sections/Slogan/slogan'
import ScrollBehaviour from './Hooks/scrollbehaviour';
import Services from './Sections/Services/services';
import Portfolio from './Sections/Portfolio/portfolio';
import Footer from './Sections/Footer/footer';

import Clients from './Sections/Clients/clients';
import Testimonials from './Sections/Testimonials/testimonials';

import { ParallaxProvider } from 'react-scroll-parallax';
import ReactGA from "react-ga4";






function App() {

  ScrollBehaviour();
  const MEASERMENT_ID = "G-WN6DVXH369";
  ReactGA.initialize(MEASERMENT_ID);

  return (
    <ParallaxProvider>
      <div class="website-container"  id="website-container">
      <Navbar/>
      <Hero/>
      <Slogan/>
      <Services/>
      <Portfolio/>
      <Clients/>
      <Testimonials/>
      <Footer/>
      </div>
    </ParallaxProvider>
  )

  
}

export default App;
