import './quote.scss';
import Close from '../../Assets/Close.svg';
import { easeOut, motion, easeInOut } from 'framer-motion';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Quote = (props) => {

    const form = useRef();
    

    const sendEmail = (e) => {
    e.preventDefault();
    

    const failureMessage = document.querySelector('.failure-message')
    const successMessage = document.querySelector('.success-message')
    const emailInput = document.querySelector('.email-field')
    const nameInput = document.querySelector('.name-field')
    const messageInput = document.querySelector('.message-field')

    if( emailInput.value == "" || nameInput.value == "" || messageInput.value == ""){
        failureMessage.style.display = "block";
        successMessage.style.display = "none";
        return
    }
    else{
        emailjs
      .sendForm('service_6exifkk', 'template_rykcqjr', form.current, {
        publicKey: '1S8wPeBcMoKiymHRQ', user_email: "sales@xenithstudio.com"
      })
      .then(
        () => {
          successMessage.style.display = "block";
          failureMessage.style.display = "none";
        },
        (error) => {
          failureMessage.style.display = "block";
          successMessage.style.display = "none";
        },
      );

    }

    
  };



    return (props.trigger) ? (
        <motion.div
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        transition={{duration: 0.2, ease: easeOut}}
        viewport={{once: true}}
            className="quote">
            <div className="overlay">
                <motion.div
                initial={{y:100, opacity: 0}}
                animate={{y:0, opacity: 1}}
                transition={{delay: 0.2, duration: 0.5, ease: easeOut}}
                viewport={{once: true}}
                className="quote-content">
                <img src={Close} alt="close button" onClick={() => {props.setTrigger(false)}}></img>
                    <div class="quote-body">
                    <h5>THANK YOU FOR TAKING INTEREST IN 
                        <span> XENITH STUDIO!</span> 
                    </h5>

                    <div className="quote-form">
                        <form ref={form} onSubmit={sendEmail}>
                            <input class="name-field" type="Full Name" placeholder="Full Name"  name="user_name"></input>
                            <br/>
                            <input class="email-field" type="Email" placeholder="Email" name="user_email"></input>
                            <br/>
                            <textarea class="message-field" placeholder="Type your message" rows = "5" cols = "50" name="message"></textarea>
                            <input value="SEND" class="send-button" type="submit"></input>
                        </form>
                        <motion.div initial={{y:100, opacity: 0}}
                            animate={{y:0, opacity: 1}}
                            transition={{delay: 0.2, duration: 0.5, ease: easeOut}}
                            viewport={{once: true}} 
                            class="success-message">Your message has been sent</motion.div>
                        <motion.div initial={{y:100, opacity: 0}}
                            animate={{y:0, opacity: 1}}
                            transition={{delay: 0.2, duration: 0.5, ease: easeOut}}
                            viewport={{once: true}} 
                            class="failure-message">Something went wrong</motion.div>
                    </div>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    ) : "";
}

export default Quote