import { useScroll, motion, progress, useTransform } from "framer-motion";
import { useRef, useEffect } from "react";
import React from 'react';

export default function Paragraph({value}) {

    const element = useRef(null);
    const { scrollYProgress } = useScroll({
        target: element,
        offset: ['start 0.6', 'start 0.2']
    })

    

    

    const words = value.split(" ");

    return(
        <h4
        ref={element} className="slogan-text-container">
        {words.map((word, i) => {
            const start = i / words.length;
            const end = start + (1 / words.length)
            return <Word key={i} range={[start, end]} progress={scrollYProgress}>{word}</Word>
        })}
        </h4>
        // <motion.h4 ref={element} style={{opacity: scrollYProgress}}>{value}</motion.h4>
    )
}

const Word = ({children, range, progress}) => {
    const opacity = useTransform(progress, range, [0, 1])
    return(
        <span className="slogan-text">
            <span className="slogan-shadow">{children}</span>
            <motion.span style={{opacity}}>
            {children}
            </motion.span>
            
        </span>
        
    )
}