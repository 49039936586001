import './portfolio.scss'
import Saambhavi from '../../Assets/Saambhavi.webp'
import WasteToWarmth from '../../Assets/W2W.webp'
import NeuralNexus from '../../Assets/NeuralNexus.webp'
import { motion, useScroll, easeOut, useTransform} from 'framer-motion';
import { useRef } from 'react';


const HorizontalScrollCarousel = () => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: targetRef,
    });
  
    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-45%"]);
    
  
    return (
      <div class="sticky-section">
      <section ref={targetRef} className="horizontal-scroll-section">
        <div className="horizontal-scroll-div">
        <motion.h4
            className='portfolio-title'
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ duration: 0.5, ease: easeOut}}
            viewport={{once: true}}>
                Portfolio</motion.h4>

            <motion.h6
            className='portfolio-subtitle'
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.1, duration: 0.5, ease: easeOut}}
            viewport={{once: true}}
            >Take a look at our past projects</motion.h6>
          <motion.div style={{x}} className="horizontal-scroller">
            {portfolioItems.map((item) => (
              <PortfolioItem project= {item.project}
              projectName = {item.projectName}
              projectType = {item.projectType}
              projectDescription = {item.projectDescription}
              image = {item.image}
              url =  {item.url}/>
            ))}
            
            
          </motion.div>
        </div>
      </section>
      </div>
    );
  };


const PortfolioItem = (props) => {
    const element = useRef(null);
    return (
      
        <motion.div 
          initial={{y:100, opacity: 0}}
          whileInView={{y:0, opacity: 1}}
          transition={{ delay: 0.2, duration: 0.5, ease: easeOut}}
          viewport={{once: true}}
        key={props.id}
          ref={element} 
          class={`project ${props.project}`}
          onClick={() => window.open(props.url, '_blank')}>
            <div class="project-details">
            <div>
                <h5>{props.projectName}</h5>
                <p class="project-description">{props.projectDescription}</p>
            </div>
                <div class="project-type-container">
                <p>{props.projectType}</p>
                </div>
            </div>
            <div></div>
            
          
            <motion.img 
            initial={{filter: 'grayscale(100%) brightness(60%)'}}
            whileHover={{filter: 'grayscale(0%) brightness(100%)'}}
            className="project-image"
             src={props.image}></motion.img>
           
            
            
        </motion.div>
        
    );
  }

const Portfolio = () => {
    
    return(
        <section class="portfolio-container">
            <HorizontalScrollCarousel/>
        </section>
    );
}



export default Portfolio

const portfolioItems = [
    {
        project: "saambhavi",
        projectName: "Saambhavi",
        projectType: "Web Development",
        projectDescription: "A Website redesign project for an NGO",
        image: Saambhavi,
        url: 'https://www.saambhavi.org/'
    },
    {
      project: "wastetowarmth",
      projectName: 'Waste To Warmth',
      projectType: "Web Development",
      projectDescription: "An e-commerce website for a  social enterprise",
      image: WasteToWarmth,
      url: 'https://www.wastetowarmth.in/'
    },
    {
      project: "neuralnexus",
      projectName: 'Neural Nexus',
      projectType: "Web Development & AI",
      projectDescription: "A repository of machine learning models",
      image: NeuralNexus,
       url: 'https://theneuralnexus.vercel.app/'
    }
  ];