import "./services.scss";
import {easeOut, motion} from 'framer-motion';
import WebDevelopment from '../../Assets/Web-Development.webp';
import MobileDevelopment from '../../Assets/Mobile-Development.webp'
import WasteToWarmth from '../../Assets/W2W.webp'
import AI from '../../Assets/AI-Image.webp'

const Services = () => {

    return(
        <section
        
         class="services-container">
            <motion.h4
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.2, duration: 0.6, ease: easeOut}}
            viewport={{once: true}}>
            Services
            </motion.h4>

            <motion.h6
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.3, duration: 0.6, ease: easeOut}}
            viewport={{once: true}}>
            This is how we’ll help your business grow
            </motion.h6>

            <motion.div 
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.3, duration: 0.4, type: "spring", stiffness: 30}}
            viewport={{once: true}}
            class="services-grid">
                
                <div
                    class="webdev-service service">
                    <div>
                        <h6>Web Development</h6>
                    </div>
                    <motion.img 
                    initial={{filter: 'grayscale(100%) brightness(40%)'}}
                    whileHover={{filter: 'grayscale(0%) brightness(100%)'}}
                    className="service-image"
                    src={WebDevelopment}></motion.img>
                </div>

                <div
                    class="mobiledev-service service">
                    <div>
                        <h6>Mobile Development</h6>
                    </div>
                    <motion.img 
                    initial={{filter: 'grayscale(100%) brightness(40%)'}}
                    whileHover={{filter: 'grayscale(0%) brightness(100%)'}}
                    className="service-image"
                    src={MobileDevelopment}></motion.img>
                </div>
                
                
                <div
                    class="uiux-service service">
                    <div>
                        <h6>UI/UX Design</h6> 
                    </div>  
                    <motion.img 
                    initial={{filter: 'grayscale(100%) brightness(40%)'}}
                    whileHover={{filter: 'grayscale(0%) brightness(100%)'}}
                    className="service-image"
                    src={WasteToWarmth}></motion.img>
                </div>
                    
                
                
                <div 
                    class="ai-service service">
                    <div>
                        <h6>AI Solutions</h6>
                    </div>
                    <motion.img 
                    initial={{filter: 'grayscale(100%) brightness(40%)'}}
                    whileHover={{filter: 'grayscale(0%) brightness(100%)'}}
                    className="service-image"
                    src={AI}></motion.img>
                </div>
            </motion.div>
        </section>

    );
    
}

export default Services