import './clients.scss';
import AfairoLogo from '../../Assets/Afairo-Logo.png';
import SaambhaviLogo from '../../Assets/Saambhavi-Logo.png';
import {motion, easeOut} from 'framer-motion';

function Client(props) {

    return (
        <div class="client-box">
            <img src={props.clientImage} alt="client"></img>
        </div>
        
    );
  }


const Clients = () => {
    return(
        <section class="clients-container">
            <motion.h4
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.2, duration: 0.6, ease: easeOut}}
            viewport={{once: true}}
            >Happy Clients</motion.h4>
            <motion.h6
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.2, duration: 0.6, ease: easeOut}}
            viewport={{once: true}}>Clients we have worked with</motion.h6>
            <motion.ul
            initial={{y:100, opacity: 0}}
            whileInView={{y:0, opacity: 1}}
            transition={{ delay: 0.3, duration: 0.6, ease: easeOut}}
            viewport={{once: true}}
             className='clients-list'>
                <li>
                    <Client clientImage={SaambhaviLogo}/>
                </li>
                <li>
                    <Client clientImage={AfairoLogo}/>
                </li>
            </motion.ul>
        </section>

    )

    
}

export default Clients