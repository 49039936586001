
import './slogan.scss';
import Paragraph from '../../Hooks/paragraph';
import { easeOut, motion } from 'framer-motion';


const slogan = "We engineer digital solutions that are tailored to your needs and goals";
const Slogan = () => {

    const textVariants = {
        hidden: { opacity: 0, scale: 0.8, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)' },
        visible: { opacity: 1, scale: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)', transition: { duration: 5 } },
      };
   
    return(
        <section class="slogan-containter" data-scroll-section>
            
            <div>
                    <motion.h6
                    className='slogan-header'
                    initial={{y:100, opacity: 0}}
                    whileInView={{y:0, opacity: 1}}
                    transition={{duration: 0.6, ease: easeOut}}
                    viewport={{once: true}}>
                    What We Do</motion.h6>
                <Paragraph value={slogan}/>
            </div>
        </section>
    )


};

export default Slogan;