
import { useEffect } from 'react';
import Lenis from '@studio-freight/lenis'

function ScrollBehaviour(){

    

    useEffect(() => {
        const lenis = new Lenis({
          duration: 2,
         
        })
        function raf(time) {
          lenis.raf(time)
          requestAnimationFrame(raf)
        }
        requestAnimationFrame(raf)
      },[])
      

     

      
};

function changeSloganBgColor(){

  
  
  if(window.scrollY > window.innerHeight*0.5 && window.scrollY < window.innerHeight*3){
    
    document.body.classList.add("dark-bg-color")
    document.body.classList.remove("light-bg-color")
    document.querySelector("h4").classList.add("light-color-opacity");
    document.querySelector("h4").classList.remove("dark-color-opacity");
    const portfolioTitle = document.querySelector(".portfolio-title");
    portfolioTitle.style.color = "#FBFBFB";
    const portfolioSubTitle = document.querySelector(".portfolio-subtitle");
    portfolioSubTitle.style.color = "#FBFBFB";

    
  }
  else{
    document.body.classList.remove("dark-bg-color")
    document.body.classList.add("light-bg-color")

    const portfolioTitle = document.querySelector(".portfolio-title");
    portfolioTitle.style.color = "#212830";
    const portfolioSubTitle = document.querySelector(".portfolio-subtitle");
    portfolioSubTitle.style.color = "#212830";
  }

    

}

window.addEventListener('scroll', changeSloganBgColor)

export default ScrollBehaviour;