import "./navbar.scss";
import Xenith from "../../Assets/Xenith.svg";
import Arrow from "../../Assets/Arrow.svg";
import {motion } from "framer-motion"
import Quote from '../Quote/quote'
import { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";


const Navbar = () => {

    const [quoteOpen, setQuoteOpen] = useState(false);
    
    
  
    
    
    

    return(
    <motion.nav className="nav-container"
            initial={{y:100, opacity: 0}}
             animate={{y:0, opacity: 1}}
             transition={{ duration: 0.6, type: "spring", stiffness: 30}}
             viewport={{once: true}}>
            <img class="xenith-logo" src={Xenith} alt="Xenith Logo"></img>
            <IoMenu class="hamburger" size={35} onClick={ShowMenu}/>

            <div class='nav-divider'>
            <ul class='nav-items'>
            <li class='nav-item' onClick={() => ScrollToSec('services')}>SERVICES</li>
                <li class='nav-item' onClick={() => ScrollToSec('portfolio')}>PORTFOLIO</li>
                <li class='nav-item' onClick={() => ScrollToSec('testimonials')}>TESTIMONIALS</li>
            </ul>
            <div onClick={() => setQuoteOpen(true)} class="contact-button">
                <p>GET A QUOTE</p>
                <img alt="arrow" src={Arrow}></img>
            </div>
            </div>
            <div class='nav-divider-mobile'>
                <div class="x-button-holder"><IoClose onClick={CloseMenu} class="nav-close" size={40}/></div>
            <ul class='nav-items'>
                <li class='nav-item' onClick={() => ScrollToSec('services')}>SERVICES</li>
                <li class='nav-item' onClick={() => ScrollToSec('portfolio')}>PORTFOLIO</li>
                <li class='nav-item' onClick={() => ScrollToSec('testimonials')}>TESTIMONIALS</li>
            </ul>
            <div onClick={() => setQuoteOpen(true)} class="contact-button">
                <p>GET A QUOTE</p>
                <img alt="arrow" src={Arrow}></img>
            </div>
            </div>

            <Quote trigger={quoteOpen} setTrigger={setQuoteOpen}></Quote>
    </motion.nav>
    )

    
    
    function ScrollToSec(type){
        const services = document.querySelector(".services-container");
        const portfolio = document.querySelector(".portfolio-container");
        const testimonials = document.querySelector(".testimonials-container");
        const menu = document.querySelector('.nav-divider-mobile')
        

        if(type==="services"){
            services.scrollIntoView({behavior: 'smooth', block: "start"})
            menu.style.display = 'none';

        }
        else if(type==="portfolio"){
            portfolio.scrollIntoView({behavior: 'smooth', block: "center"})
            menu.style.display = 'none';
            
        }
        else if(type==="testimonials"){
            testimonials.scrollIntoView({behavior: 'smooth', block: "start"})
            menu.style.display = 'none';

        }


    }

    function CloseMenu(){
        const menu = document.querySelector('.nav-divider-mobile')
        const closeButton = document.querySelector('.hamburger')
        if(closeButton.style.display === 'none'){
            menu.style.display = 'flex';
        }
        else{
            menu.style.display = 'none';
        }
        
    }
    function ShowMenu(){
        const menu = document.querySelector('.nav-divider-mobile')
        menu.style.display = 'flex';
    }
}

export default Navbar