import './hero.scss';
import HeroMask from '../../Assets/Hero-Mask.svg'
import HeroImg from '../../Assets/HeroImage.webp'
import { useEffect } from 'react';
import { motion } from "framer-motion"
import { Parallax } from 'react-scroll-parallax';




const Hero = () => {

    
      
    useEffect(() => {

        const scrollButtonHero = document.getElementById('scroll-button');
        const scrollButtonFooter = document.getElementById('scroll-button-top');
        const slogan = document.querySelector('.slogan-header')
        const nav = document.querySelector('.nav-container')
        
            
        scrollButtonHero.onclick = function() {
        slogan.scrollIntoView({behavior: 'smooth', block: "center"})}
        
        scrollButtonFooter.onclick = function() {
            nav.scrollIntoView({behavior: 'smooth', block: "start"})}

        
        
    }, [])
    
     

    
        
  
    

    return (    
        <section class="hero-container" data-scroll-section>
            <motion.h1
             initial={{y:100, opacity: 0}}
             animate={{y:0, opacity: 1}}
             transition={{ delay: 0.2, duration: 0.3, type: "spring", stiffness: 30}}
             viewport={{once: true}}
            class="hero-text">DIGITAL PRODUCT ENGINEERING</motion.h1>
            <div class="hero-middle">
            <motion.div 
            initial={{y:20, opacity: 0}}
            animate={{y:0, opacity: 1}}
            transition={{ delay: 0.4, duration: 0.3, type: "spring", stiffness: 30}}
            viewport={{once: true}}
            class="mask" style={{
                WebkitMaskImage: `url(${HeroMask})`,
                maskImage: `url(${HeroMask})`,
            }}>
                <Parallax class="parallax" speed={-30}>
                <img class="hero-img" src={HeroImg} alt="cover"></img>
                </Parallax>
            </motion.div>
            <motion.p 
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            transition={{ delay: 1.2, duration: 0.6, type: "spring", stiffness: 30}}
            viewport={{once: true}}
            class="hero-description">
                We prioritise efficiency and quality in every one of our solutions</motion.p>
            <img class="hero-mobile-image" src={HeroImg} alt="hero"></img>
            </div>
            
                <motion.div 
                initial={{y:100, opacity: 0}}
                animate={{y:0, opacity: 1}}
                transition={{ delay: 0.2, duration: 0.6, type: "spring", stiffness: 30}}
                viewport={{once: true}}
                    class="scroll-button" id="scroll-button">
                    <p>SCROLL</p>
                    <div class="arrow"></div>
                </motion.div>
  
        </section>
    )
}

export default Hero;