import './testimonials.scss'
import {motion, easeOut} from 'framer-motion';
import { FaQuoteLeft } from "react-icons/fa";
import vijaySir from '../../Assets/mr_Vijay.jpeg'
import anjuMaam from '../../Assets/ms_Anju.webp'

function Testimonial(props){
    return(
        <div className='testimonial-container'>
            <FaQuoteLeft class="quote-icon" size={35} style={{color: "var(--dark-color)"}}/>
            <p class="testimonial-content">{props.text}</p>
            <div class="testimonial-bottom">
                <img class="profile-picture" src={props.profile} alt="profile"></img>
            <div>
            <p className='testimonial-name'>{props.name}</p>
            <p className='testimonial-title'>{props.title}</p>
            </div>
            </div>
        </div>
    )
}

const Testimonials = () => {
    return(
        <section className='testimonials-container'>
            <motion.h4
             initial={{y:100, opacity: 0}}
             whileInView={{y:0, opacity: 1}}
             transition={{ delay: 0.2, duration: 0.6, ease: easeOut}}
             viewport={{once: true}}>Testimonials</motion.h4>
            <motion.h6
             initial={{y:100, opacity: 0}}
             whileInView={{y:0, opacity: 1}}
             transition={{ delay: 0.2, duration: 0.6, ease: easeOut}}
             viewport={{once: true}}>Feedback from our clients</motion.h6>
            <motion.ul
             initial={{y:100, opacity: 0}}
             whileInView={{y:0, opacity: 1}}
             transition={{ delay: 0.3, duration: 0.6, ease: easeOut}}
             viewport={{once: true}} className='testimonials-list'>
                <li><Testimonial 
                text="Working with Xenith Studio has been transformative for Saambhavi NGO. They displayed a deep understanding of our mission and vision, translating it into a visually stunning and highly functional website. Their dedication to capturing our essence and communicating it online is commendable. I highly recommend Xenith Studio to any organization seeking a strategic partner in web development."
                name="Ms. Anju Pandey"
                title="Founder and President of Saambhavi"
                profile={anjuMaam}
                
                /></li>
                <li><Testimonial 
                text="Xenith Studio has been essential in creating Saambhavi NGO's website. With their exceptional technical and creative expertise they created a user-friendly design and transformed it into a website. Their flexibility and quick response to feedback make them the best choice for web design/development projects."
                name="Mr. Vijay Shanker Dubey"
                title="Vice President of Software Engineering at J.P. Morgan"
                profile={vijaySir}
                /></li>
            </motion.ul>

        </section>
    )
}

export default Testimonials